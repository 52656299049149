import { Container } from "react-bootstrap";
import Layout from "../Components/Layout/Layout";
import { Form } from "react-bootstrap";
import Dashboard from "./Dashboard";
import { useNavigate } from "react-router-dom";

export default function CarAdded() {
  const navigate = useNavigate();

  const navigateForDone = () => {
    navigate("/dashboard");
  };

  const navigateForAddMore = () => {
    navigate("/add-car");
  };

  return (
    <div>
      <Container fluid className="">
        <div className=" sign-banner-part">
          <div className="inner-login-box">
            <div className="center-align-box">
              <div className="center-bank-details">
                <div className="login-cmn-box">
                  <Form>
                    <div className="congratulation-page">
                      <div className="congratulation-heading">
                        <img
                          src={require("../Assets/Images/congratulations.png")}
                        ></img>
                        <h1> Car added </h1>
                        <p>
                          Your car has been added successfully. Add more cars or
                          send for approval of this car to the admin.
                        </p>
                      </div>
                      <div className="done-add-btns ">
                        <button
                          variant="primary"
                          type="submit"
                          className="done-btn add-more-cars-ps"
                          onClick={navigateForDone}
                        >
                          Done
                        </button>
                        <button
                          variant="primary"
                          type="submit"
                          className="add-cars-btn add-more-cars-ps"
                          onClick={navigateForAddMore}
                        >
                          Add More Cars
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
